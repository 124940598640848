export const servieUrl={

        "url":"https://capitalsstock77.pythonanywhere.com/",
    
        //  "url":"https://stockmarketing.pythonanywhere.com/",
    
         "otpurl":"https://capitalsstock77.pythonanywhere.com/"

        // "otpurl" : "https://harshitgangwar002.pythonanywhere.com/"

    
    }